import { combineReducers } from 'redux';
import theme from './theme/themeSlice';
import auth from './auth';
import base from './base';
import errors_server from './errors-server';
import {notifications} from "./Notifications";
import mailing from "./mailing";

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        errors_server,
        notifications,
        mailing,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
