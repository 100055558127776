import React, {memo, useMemo, lazy, Suspense, useEffect} from "react";
import {Loading} from "components/shared";
import {useDispatch, useSelector} from "react-redux";
import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_MODERN,
  LAYOUT_TYPE_SIMPLE,
  LAYOUT_TYPE_STACKED_SIDE,
  LAYOUT_TYPE_DECKED,
  LAYOUT_TYPE_BLANK,
} from "constants/theme.constant";
import useDirection from "utils/hooks/useDirection";
import useLocale from "utils/hooks/useLocale";
import {useTokenUpdate} from "../../hooks/useTokenUpdate";
import {useSessionContext} from "supertokens-auth-react/recipe/session";
import {onSignInSuccess, set_is_session} from "../../store/auth/sessionSlice";
import {useGetMe} from "./get-me";
import {SupabaseNotice} from "./supabase-notice";

const layouts = {
  [LAYOUT_TYPE_CLASSIC]: lazy(() => import("./ClassicLayout")),
  [LAYOUT_TYPE_MODERN]: lazy(() => import("./ModernLayout")),
  [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import("./StackedSideLayout")),
  [LAYOUT_TYPE_SIMPLE]: lazy(() => import("./SimpleLayout")),
  [LAYOUT_TYPE_DECKED]: lazy(() => import("./DeckedLayout")),
  [LAYOUT_TYPE_BLANK]: lazy(() => import("./BlankLayout")),
};

const Layout = () => {
  const uuid = useSelector((state) => state.auth.user.uuid);

  const sessionContext = useSessionContext();

  const dispatch = useDispatch();



  useEffect(() => {
    if (sessionContext.loading) {
      return;
    }
    if (!sessionContext.loading && sessionContext.doesSessionExist && sessionContext.userId) {
      dispatch(set_is_session(true));
      dispatch(onSignInSuccess({access: "", access_supabase: ""}));
    } else {
      dispatch(set_is_session(false));
    }
  }, [sessionContext?.loading, sessionContext?.doesSessionExist, sessionContext.userId, dispatch]);

  //

  const layoutType = useSelector((state) => state.theme.layout.type);

  useDirection();

  useLocale();

  /** Обновление JWT*/
  useTokenUpdate();

  /** Запрос me */
  useGetMe();


  const AppLayout = useMemo(() => {
    if (uuid) {
      return layouts[layoutType];
    }
    return lazy(() => import("./AuthLayout"));
  }, [layoutType, uuid]);

  return (
    <Suspense
      fallback={
        <div className="flex flex-auto flex-col h-[100vh]">
          <Loading loading={true} />
        </div>
      }
    >
      <AppLayout />
      <SupabaseNotice/>
    </Suspense>
  );
};

export default memo(Layout);
